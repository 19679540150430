import React from 'react';
import { connect } from "react-redux";
import { 
    Box, 
    Typography,
    Button
} from '@mui/material';
import '../PujaConfirmationComponent/PujaConfirmationComponent.css';
import { useLocation,useNavigate  } from 'react-router-dom';




const PujaSuccessComponent =  ({
    pujabookingdetails,
}) => {
    const navigate = useNavigate();
   let attendingText;
   switch (pujabookingdetails.pujaPerson) {
       case 'Yes':
           attendingText = "You will be attending in person.";
           break;
       case 'No':
           attendingText = "You will NOT be attending in person.";
           break;
       default:
           attendingText = "";
        }
        
            const {state} = useLocation();
          console.log(state) ; 
   function handleClickPujaSuccess() {
    navigate("/puja-gallery");
}
    return (
    <>

        
        <Box
            className='puja-success'
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1 },
                mx: { xs: 2, sm: 10, md: 20, lg: 30 }, // Adjusts horizontal margin based on screen size
                py: { xs: 10, md: 20 } 
            }}
            noValidate
            autoComplete="off"
        >
            <div>
           
                <Box className="form-control-spacing">
                    <Typography sx={{ pb: 3, fontWeight: '600', color: '#010101' }}>Success:</Typography>
                    <Box className="label-puja-confirm" sx={{ pb: 3 }}>
                        <label>Your booking for <b>{pujabookingdetails.pujaName}</b> has been made successfully </label><br></br>
                        <label>A confirmation email has been sent to you at <b>{state.userEmail}</b></label>
                    </Box>
                </Box>

                <Box sx={{textAlign: 'center'}}>
                    <Button onClick={() => handleClickPujaSuccess()} variant="contained">Home</Button>
                </Box>
            </div>
        </Box>
    </>
  )
}
const mapStateToProps = (state) => {
    return{
        pujabookingdetails: state.puja.pujabookingdetails,
    };
  
   }

export default connect(mapStateToProps,{
    
  })(PujaSuccessComponent);