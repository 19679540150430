
import { useEffect, useCallback  } from "react";
import { connect } from "react-redux";
import { useParams , useNavigate  } from 'react-router-dom';
import useRazorpay from "react-razorpay";
import axios from 'axios';
import * as config from "../../assets/js/constant.js";

import { 
  Box, 
  Stepper, 
  Step, 
  StepLabel, 
  StepContent, 
  Button, 
  Container, 
  Typography, 
  Grid,
  Divider,
  CircularProgress
} from '@mui/material';

import '../MyPujaBookingComponent/MyPujaBookingComponent.css';
import HeaderComponent from '../../common/HeaderComponent/HeaderComponent.js';
import PujaHomaBookingComponent from '../PujaHomaBookingComponent/PujaHomaBookingComponent.js';
import DevoteeDetailsComponent from '../DevoteeDetailsComponent/DevoteeDetailsComponent.js';
import PujaConfirmationComponent from '../PujaConfirmationComponent/PujaConfirmationComponent.js';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent.js';
import PaymentDetailsComponent from '../PaymentDetailsComponent/PaymentDetailsComponent.js';
import FooterComponent from '../../common/FooterComponent/FooterComponent.js';
import { useImmer } from 'use-immer';
import {setpujabookingdetails} from '../../store/actions/puja.js';
import {setpaymentdetails} from '../../store/actions/puja.js';
import {getpujadetail} from '../../store/actions/puja.js';
import {paymentprocess} from '../../store/actions/puja.js';
import {bookPujaComplete} from '../../store/actions/puja.js';
import { AddBox } from "@mui/icons-material";



const  MyPujaBookingComponent = ({
  paymentprocess,
  getpujadetail,
  pujadetails,
  setpujabookingdetails,
  pujaorderdetails,
  pujabookingdetails,
  bookPujaComplete
}) => {

  const navigate = useNavigate();
console.log(pujadetails);
  const PUJAHOMAINITIALVALUE = {
    entity: "PUJA",
    pujaID : pujadetails.PujaID,
    pujaName:pujadetails.PujaName,
    pujaDate: "",
    currency :'INR',
    Amount: pujadetails.Amount,
    pujaPerson : "",
    pujaDetail : ""
  }

  const PUJAHOMAUSERVALUE = {
    pujaUserName : "",
    pujaUserContact : "",
    pujaUserAlt : "",
    pujamailName: "",
    pujamailAddress : "",
    pujamailPincode : "",
    pujamailMobile : ""
  }

  const PUJAPAYMENTVALUES = {
    MappingID : "",
    OrderID: "",
    userPanNo : "",
    userEmail : "",
    userComments : "",
    userPanNo : ""
  }

  const [getpujahomadetails,setpujahomadetails] = useImmer(PUJAHOMAINITIALVALUE);
  const [getpujahomauserdetails,setpujahomauserdetails] = useImmer(PUJAHOMAUSERVALUE);
  const [getpaymentdetails,setpaymentdetails] = useImmer(PUJAPAYMENTVALUES);
  const [getloader,setloader] =  useImmer(false);

  const [activeStep, setActiveStep] = useImmer(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEdit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleConfirm = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  const steps = [
    {
      label: 'Book A Puja/Homa',
      PujaHomaBooking:  <PujaHomaBookingComponent getpujahomadetails={getpujahomadetails} setpujahomadetails={setpujahomadetails}/>,
      nextStep: function(){
        const amount = parseInt(getpujahomadetails.Amount);
        if((amount >= pujadetails.Amount)&&(getpujahomadetails.pujaDate) && (getpujahomadetails.pujaPerson.length>0) && (getpujahomadetails.pujaDetail.length<=300)){ 
          setpujabookingdetails(getpujahomadetails) 
         handleNext(); 
        }
      },
      buttons: ['Next']
    },
    {
      label: 'Devotee Details',
      DevoteeDetails: <DevoteeDetailsComponent getpujahomauserdetails = {getpujahomauserdetails} setpujahomauserdetails = {setpujahomauserdetails} />,
      nextStep : function(){
        if ((getpujahomauserdetails.pujaUserName) && (getpujahomauserdetails.pujaUserContact.length === 10) && (getpujahomauserdetails.pujaUserAlt.length === 0 || getpujahomauserdetails.pujaUserAlt.length === 10) && (getpujahomauserdetails.pujaUserAlt !== getpujahomauserdetails.pujaUserContact) && (getpujahomauserdetails.pujamailPincode.length === 0 || getpujahomauserdetails.pujamailPincode.length === 6)) {
          setpujabookingdetails(getpujahomauserdetails)
          handleNext();
        }
      },
      buttons: ['Back', 'Submit']
    },
    {
      label: 'Confirmation',
      PujaConfirmation: <PujaConfirmationComponent />,
      nextStep : function(){
        setloader(true);
        handlePayment(pujabookingdetails);
      },
      buttons: ['Edit', 'PayNow']
    },
      {
        label: 'Payment Details',
        PaymentDetails: <PaymentDetailsComponent getpaymentdetails = {getpaymentdetails} setpaymentdetails = {setpaymentdetails} Amount = {getpujahomadetails.Amount} />,
        nextStep: function(){
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
           const regpan = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
          if(emailRegex.test(getpaymentdetails.userEmail) && (getpaymentdetails.userComments.length<=120) && (getpaymentdetails.userPanNo.length === 0 || regpan.test(getpaymentdetails.userPanNo))) {
            console.log(regpan.test(getpaymentdetails.userPanNo));
            bookPujaComplete(getpaymentdetails);
            navigate("/puja-success", {state: {userEmail : getpaymentdetails.userEmail}});       
          }
        },
        buttons: ['Submit']
      },
  ];
 
  
  const renderButtons = (step) => {
    return step.buttons.map((button, index) => {
      switch (button) {
        case 'Back':
          return (
            <Button sx={{ mt: 1, mr: 1, color: 'grey', borderColor: 'grey !important' }} variant="outlined" key={index} onClick={handleBack}>
              Back
            </Button>
          );
        case 'Next':
          return (
            <Button sx={{ mt: 1, mr: 1 }} variant="contained" key={index} onClick={step.nextStep}>
              Next
            </Button>
         );
         case 'Submit':
           return (
             <Button sx={{ mt: 1, mr: 1 }} variant="contained" key={index} onClick={step.nextStep}>
               Submit
             </Button>
           );
         case 'Edit':
           return ( 
             <Button sx={{ mt: 1, mr: 1, color: 'grey', borderColor: 'grey !important' }} variant="outlined" key={index} onClick={handleEdit}>
               Edit
             </Button>
           );
         case 'PayNow':
           return (
             <Button sx={{ mt: 1, mr: 1 }} variant="contained" key={index} onClick={step.nextStep}>
               Pay Now
             </Button>
           );
         default:
           return null;
       }
     });
   };
 



  const {pujaID} = useParams();
  useEffect(() => {
    console.log(pujaID);
  getpujadetail(pujaID);
  }, [pujaID])

  useEffect(() => {
    setpujahomadetails((draft) => {
        draft.pujaID = pujadetails.PujaID;
        draft.pujaName = pujadetails.PujaName;
        draft.Amount = pujadetails.Amount;
    })

}, [pujadetails]);


  const [Razorpay] = useRazorpay();

  const handlePayment = useCallback((pujabookingdetails) => {
    axios.post(config.API_BASE_URL+'apiCall.php?apicall=pujaOrderCreation',
  {data : pujabookingdetails })
  .then(response => {
    var data = response.data.Data;
    console.log(data);
    setpaymentdetails((draft)=>{
      draft.OrderID = data.OrderID;
  })

    const options = {
      key: data.key_id,
      amount: data.Amount,
      currency: data.Currency,
      name: "NSR Trust",
      description: `Booking for puja ${getpujahomadetails.pujaName}`,
      image: "https://sridevitemple.com/src/assets/god-image.jpg",
      order_id:  data.OrderID,
      "method": {
        "netbanking": 1,
        "card": 1,
        wallet: false, // This disables wallet
        upi: false, // This disables UPI
      },
      handler: (res) => {  
        paymentprocess(res);
        handleNext();
        setloader(false);
      },
      prefill: {
      name : pujabookingdetails.pujaUserName,
      contact : pujabookingdetails.pujaUserContact
      },
      notes: {
        address: "SSRA, Nanganallur",
      },
      theme: {
        color: "#3399cc",
      },
    };

    console.log(options);

    const rzpay = new Razorpay(options);
  
    rzpay.on("payment.failed", function (response) {
      alert(response.error.description);
    });
    rzpay.open();
    setloader(false)
  })
  .catch(error => console.log(error));
}, [Razorpay]);
   


return (
    <>
        <HeaderComponent />
        <Box sx={{ mt: 3 }}>
          <Container>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={5}>
                <Box className="user-selected-puja" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                    <img
                        className='puja-image'
                        src={config.image+pujadetails.Image}
                        alt="pujaImage"
                    />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#3875BF', mb: 2 }}>
                  {pujadetails.PujaName}
                  </Typography>
                  <Typography sx={{ lineHeight: 2.3, color: '#8E8E8E' }}>
                   {pujadetails.Description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </Box>
        <Box className="mypuja-booking-screen">
            <Container>
                <Box sx={{ py: 2 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                        <Step key={step.label} >
                          <Box className="stepper-label">
                            <StepLabel>
                              {step.label}
                            </StepLabel>
                          </Box>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                {step.PujaHomaBooking}
                                {step.DevoteeDetails}
                                {step.PujaConfirmation}
                                {step.PaymentDetails}
                                {steps[activeStep] && (
                                  <Box className="stepContent-btn" sx={{ mb: 2, textAlign: 'right', mx: 20 }}>
                                    { getloader ? (<Box sx={{ mt: 1, mr: 1 }} variant="contained"><CircularProgress /></Box>) : renderButtons(steps[activeStep]) }
                                  </Box>
                              )}

                                {/* <Box className="stepContent-btn" sx={{ mb: 2, textAlign: 'right', mx: 20 }}>
                                  <Button
                                      disabled={index === 0}
                                      variant="outlined"
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1 }}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                      variant="contained"
                                      onClick={step.nextStep}
                                      sx={{ mt: 1, mr: 1 }}
                                  >
                                    {index === steps.length - 1 ? 'Submit' : 'Next'}
                                  </Button>
                                </Box> */}
                                <Divider />
                            </StepContent>
                        </Step>
                        ))}
                    </Stepper>                    
                </Box>
            </Container>
            <FooterComponent />
        </Box>
        <BottomAppBarComponent />
    </>
  )
}
 const mapStateToProps = (state) => {
  return{
      pujadetails: state.puja.pujadetails,
      pujaorderdetails : state.puja.pujaorderdetails,
      pujabookingdetails: state.puja.pujabookingdetails
  };

 }

export default connect(mapStateToProps,{
  paymentprocess,
  getpujadetail,
  setpujabookingdetails,
  setpaymentdetails,
  bookPujaComplete
})(MyPujaBookingComponent);