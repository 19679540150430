import React from 'react';
import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import MyBooksComponent from '../MyBooksComponent/MyBooksComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import ComingSoonComponent from '../ComingSoonComponent/ComingSoonComponent';



export default function GetBooksGalleryComponent() {
  return (
    <>
      <HeaderComponent />
      <ComingSoonComponent component="Books"/>
      <FooterComponent />
      <BottomAppBarComponent />
    </>
  )
}
