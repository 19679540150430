import React from 'react';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import "./ContactusComponent.css";

import {
    Box,
    Container
} from '@mui/material';

export default function ContactusComponent() {

    return (
        <>
            <HeaderComponent />
            < Box className="puja-gallery-page">
                <Container sx={{ px: 1 }}>
                <div className='div-space'>
                <h3 class="puja-details">Address:</h3>
                <p class="sub-title"> 18/29 16th Street, <br/> 
                    TG Nagar,<br/>
                    Nanganallur, <br/>
                    Chennai <br/>
                    INDIA <br/>
                    600061 
                </p>
                </div>


                <div className='div-space'>
                <h3 class="puja-details">Contact:</h3>
                <strong>Phone: </strong> 99625 14134<br/>
                <strong>Email: </strong> <a href="mailto:sridevitemple@gmail.com">sridevitemple@gmail.com</a>
                </div>
                </Container>
            </Box>


            <FooterComponent />
            <BottomAppBarComponent />
        </>
    )

}