import React from 'react';
import { connect } from "react-redux";
import { useImmer } from 'use-immer';
import {getpayment} from '../../store/actions/puja.js';


import { 
    Box, 
    FormControl, 
    Grid, 
    TextField, 
    Typography,
    FormHelperText
} from '@mui/material';

const  PaymentDetailsComponent = ({

    getpaymentdetails,
    setpaymentdetails,
    Amount
    


}) => {

    const ErrorInitialState = {
        error: false,
        message:""
    }


    const [isPan, setIsPan] = useImmer(ErrorInitialState);
    const [isEmail, setEmail] = useImmer({
        error: true,
        message:"Please enter valid email"
    });
    const [isComments,setComments] = useImmer(ErrorInitialState);
 
    const HandleEmail = (event) => {
        console.log(isEmail);
        const email = event.target.value.trim().toLowerCase();
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
        setpaymentdetails((draft) => {
            draft.userEmail = email;
        });
    

        if (!emailRegex.test(email)) {
            setEmail((draft) => {
                draft.error = true;
                draft.message = "Please enter valid Email";
            });
        } else {
            setEmail((draft) => {
                draft.error = false;
                draft.message = "";
            });
        }
    };

    const HandlePan = (event) => {
        const Pan = event.target.value.trim().toUpperCase();
        const regpan = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;   

        setpaymentdetails((draft) => {
            draft.userPanNo = Pan;
        });
        if (!regpan.test(Pan)) {
            setIsPan((draft) => {
                console.log("ITS COME FALSE");
                draft.error = true;
                draft.message = "Please enter valid Pan Number";
            });
        } else {
            setIsPan((draft) => {
                console.log("ITS COME TRUE");
                draft.error = false;
                draft.message = "";
            });
        }
    }

    const handleComments = (event) => {
        // setpaymentdetails((draft) => {
        //     draft.userComments = event.target.value;
        //     })
        const Comments = event.target.value;
        if (Comments.length <= 120) {
            setpaymentdetails((draft) => {
                draft.userComments = Comments;
            });
            setComments((draft) => {
                draft.error = false;
                draft.message = "";
            });
        } else {
            setComments((draft) => {
                draft.error = true;
                draft.message = "Comments should not exceed 120 characters";
            });
        }
    }

  return (
    <>
        <Box
            className='puja-confirmation'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Amount Paid</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {/* <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl> */}
                            <Typography sx={{ color: '#8E8E8E' }}>{Amount}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Payment RER/UTR</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {/* <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl> */}
                            <Typography sx={{ color: '#8E8E8E' }}>{getpaymentdetails.OrderID}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your PAN <span className='label-required'></span></label>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined" value = {getpaymentdetails.userPanNo} onChange={HandlePan} inputProps={{maxlength:10}}/>
                                {isPan && (
                                <FormHelperText error>{isPan.message}</FormHelperText>
                                 )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your Email ID <span className='label-required'>*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField 
                                id="outlined-basic" 
                                variant="outlined" 
                                value = {getpaymentdetails.userEmail} 
                                onChange={HandleEmail} />
                                {isEmail.error && (
                                <FormHelperText error>{isEmail.message}</FormHelperText>
                                 )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Comments</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    rows={4}
                                    value={getpaymentdetails.userComments}
                                    onChange={handleComments}
                                />
                                <span>{getpaymentdetails.userComments.length}/120</span>
                                 {isComments.error && (
                                    <FormHelperText error>{ isComments.message }</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Box>
    </>
  )
 }
 const mapStateToProps = (state) => {
    return {
        pujabookingdetails: state.puja.pujabookingdetails,
    };
}

export default connect(mapStateToProps,{
    getpayment
    
  })(PaymentDetailsComponent);