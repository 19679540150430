import React from 'react';
import "./ComingSoonComponent.css";


import {
    Box,
    Container
} from '@mui/material';

export default function ComingSoonComponent({component}) {
    return (
        <>
        <div className="container">
        <h1>{component} Coming Soon!</h1>
        </div>
    </>
    )
    }